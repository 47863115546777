.Contact{
	&-left{
		h2{
			font-style: normal;
			font-size: rem(25);
			text-align: center;
			margin-bottom: 10px;
		}
		p{
			text-align: center;
			// font-family: "Source Sans Pro";
			i{
				font-style: normal;
			}
			span{
				display: block;
				font-size: rem(16);
				color: $color-grey-normal;
				> span{
					display: inline-block;
					margin-right: 15px;
				}
			}
		}
		@media screen and (max-width: 750px){
			@include gridle_grid( 12 );
		}
	}
	&-right{
		@media screen and (max-width: 750px){
			@include gridle_grid( 12 );
		}
	}
	&-metro{
		display: inline-block;
		@include size(20px);
		line-height: 16px;
		font-size: 12px;
		color: #FFF !important;
		margin-left: 15px;
		border-radius: 20px;
		background-color: #9B993A;
		transform: translateY(-3px);
	}

	&-map{
		@include size(100% 400px);
		background: $color-grey-bg;
		iframe{
			@include size(100%);
		}
	}
}