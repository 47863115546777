.Footer{
	margin-top: 60px;
	position: relative;

	.container{
		position: relative;
	}

	&-content{
		padding-top: 60px;
		padding-bottom: 60px;
		widows: 100%;
		font-size: rem(12);

		&:before{
			content: " ";
			background-color: #D8D8D8;
			height: 2px;
			width: 80%;
			@include position(absolute, 0 0 auto 0);
			margin: auto;
		}

		ul{
			padding: 0;
			margin: 0;
			white-space: nowrap;
			
			@media screen and (max-width: 900px){
				white-space: normal !important;
			}

			li{
				list-style-type: none;
				display: inline-block;
				margin-right: 15px;
				padding-right: 15px;
				border-right: 1px solid #C1C1C1;
				&,a{
					color: #C1C1C1;
					font-weight: 400;
				}
				a{
					text-decoration: none;
					&:hover{
						cursor: pointer;
						text-decoration: underline;
					}
				}
			}
		}

		&-left{
			font-family: "Source Sans Pro";
			@media screen and (max-width: 900px){
				@include gridle_grid( 12 );
				text-align: center !important;
			}
		}

		&-right{

			@media screen and (max-width: 900px){
				@include gridle_grid( 12 );
				text-align: center !important;
				margin-top: 15px;
				margin-bottom: 60px;
				.Input{
					display: inline-block !important;
					float: none !important;
				}
				.Input{
					transform: translateX(-20px) translateY(15px);
					@media screen and (max-width: 480px){
					}
				}
				transform: translateX(25px);
			}

			text-align: right;
			li{
				border: none !important;
				font-size: rem(18);
			}
			ul,.Input{
				display: block;
				float: right;
			}
			.Input{
				margin-top: -10px;
			}

			ul{
				@media screen and (max-width: 900px){
					display: block !important;
					float: none !important;
					margin-top: 30px;
					margin-left: -40px;
				}
			}
		}
	}
}