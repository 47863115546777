.Slide{

	@include size(100vw 590px);
	background-size: cover;
	background-position: center center;
	position: relative;
	float: left;

	> .container{
		@include size(100%);
		position: relative;
		z-index: 1000;
	}

	&-wrapper{
		@include size(100%);
		display: table;
	}

	&-content{
		display: table-cell;
		vertical-align: middle;

		h2{
			font-size: rem(60);
			font-style: normal;
			max-width: 60%;
			margin: auto;
			text-align: center;
			line-height: 100%;
			color: #FFF;

			@media screen and (max-width: 700px){
				font-size: rem(45) !important;
			}

		}

		p{
			max-width: 40%;
			margin: auto;
			color: #FFF;
			text-align: center;
			margin-top: 20px;
		}
	}

}