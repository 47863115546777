.Input{
	@include size(200px 40px);
	background-color: #F9F9F9;
	position: relative;

	&-submit{
		@include size(40px);
		@include position(absolute, 0 0 auto auto);
		line-height: 40px;
		text-align: center;
		background-color: #C1C1C1;
		font-size: rem(14);
		color: #F5F5F5;
		text-transform: uppercase;
		text-decoration: none;
		letter-spacing: 2px;
		border: none;
		&:hover{
			background-color: darken(#C1C1C1, 5%);
		}
	}
	input{
		@include size(calc(200px - 40px) 40px);
		@include position(absolute, 0 auto auto 0);
		padding-left: 15px;
		background-color: transparent;
		border: none;
		outline: none;
		color: #C1C1C1;
		font-size: rem(14);
		@include placeholder{
			color: #C1C1C1;
			font-size: rem(14);
			letter-spacing: 2px;
		}
	}
}