// Reset
@import '../../../node_modules/normalize.scss/normalize';

// Bourbon
@import 'bourbon';

// Base
@import 'base/variables';
@import 'base/base';
@import 'base/mixins';
@import 'base/icons';
@import 'base/typos';

// Vendors
@import 'vendors/animate';
@import 'vendors/grid/grid';
@import 'vendors/slick-theme';

// Components
@import 'components/Header';
@import 'components/Footer';
@import 'components/SubHeader';
@import 'components/Button';
@import 'components/Showcase';
@import 'components/Slider';
@import 'components/Slide';
@import 'components/Concept';
@import 'components/Events';
@import 'components/Event';
@import 'components/Team';
@import 'components/People';
@import 'components/Peoples';
@import 'components/Gallery';
@import 'components/Form';
@import 'components/Contact';
@import 'components/Input';

.VDatneitrappa {
  overflow: hidden;
}