.People{

	background-color: #FFF;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
	transition: all 0.6s;

	&-image{
		@include size(100% 275px);
		overflow: hidden;
		position: relative;
		&-src{
			@include position(absolute, 0 0 0 0);
			@include size(100%);
			background-size: cover;
			background-repeat: no-repeat;
			background-position: top center;
			transition: all 0.6s;
		}
	}

	&-content{
		@include size(100% 175px);
	}

	&-job{
		font-size: rem(11);
		font-family: "Raleway";
		text-align: center;
		margin-top: 20px;
		font-weight: 700;
		color: #838383;
	}

	h3{
		font-size: rem(25);
		margin-top: 15px;
		text-align: center;
		color: #303030;
	}

	p{
		margin-top: 20px;
		margin-bottom: 0;
		text-align: center;
		font-size: rem(14);
		padding: 0 20px;
		line-height: 170%;
		color: #838383;
	}

	&:hover{
		box-shadow: 0 12px 30px 0 rgba(0,0,0,0.1);
		transform: translateY(-10px);
		cursor: pointer;
	}

}