.Button{
	height: 50px;
	line-height: 50px;
	padding: 0 50px;
	text-align: center;
	display: inline-block;
	border: 2px solid $color-grey-btn;
	border-radius: 4px;
	font-family: 'Libre Baskerville', serif;
	font-weight: 400;
	font-size: rem(18);
	color: $color-grey-btn;
	text-decoration: none;
	position: relative;
	transition: all 0.2s;
	cursor: pointer;

	&:after{
		@include position(absolute, 0 0 0 0);
		@include size(100%);
		background-color: $color-grey-btn;
		transform: scaleY(0);
		content: " ";
		z-index: -1;
		transition: all 0.2s;
	}

	&:hover,&.is-selected{
		color: #FFF;
		&:after{
			transform: scaleY(1);
		}
	}
	
	&--dark:after{
		background-color: #FFF !important;
	}
	&--dark{

		&:before{
			@include position(absolute, 0 0 0 0);
			@include size(100%);
			background-color: $color-grey-btn;
			content: " ";
			z-index: -2;
		}

		color: #FFF !important;
		&:hover{
			color: $color-grey-btn !important;
		}
	}

	&--arrow,&--arrowDark{
		color: #FFF !important;
		border-color: #FFF;
		padding: 0;
		width: 40px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		&:hover{
			color: $color-grey-btn !important;
		}
		&:after{
			background-color: #FFF !important;
		}
	}

	&--arrowDark{
		border-color: $color-grey-btn;
		color: $color-grey-btn !important;
		&:hover{
			color: #FFF !important;
		}
		&:after{
			background-color: $color-grey-btn !important;
		}
	}

	&--select{
		&:after{
			display: none;
		}
		outline: none;
		background-color: #FFF;
		padding: 0 30px;
		width:250px;
		&:hover{
			color: $color-grey-btn;
		}
	}
}