.Concept{
	padding: 40px 0;
	h1,h3,p{
		text-align: center;
	}
	h1{
		margin: 0;
		margin-bottom: 20px;
	}
	p{
		max-width: 70%;
		margin: auto;
	}
	&-card{
		margin: 30px 0;
		padding: 0 20px;
		// box-sizing: border-box;

		&-content{
			h3{
				margin: 30px 0;
			}
			p{
				max-width: 80%;
				color: #838383;
			}
		}
		&-image{
			height: 270px;
			overflow: hidden;
			position: relative;
			&-src{
				@include position(absolute, 0 0 0 0);
				@include size(100%);
				background-size: cover;
			}
		}
	}
	&-cta{
		text-align: center;
		// margin-top: 20px;
	}
}