@font-face {
	font-family: 'Baskerville';
	src: url('../fonts/Baskerville.eot');
	src: url('../fonts/Baskerville.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Baskerville.woff') format('woff'),
		url('../fonts/Baskerville.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Baskerville';
	src: url('../fonts/Baskerville-Italic.eot');
	src: url('../fonts/Baskerville-Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Baskerville-Italic.woff') format('woff'),
		url('../fonts/Baskerville-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}



h1{
	font-family: 'Baskerville', serif;
	font-size: rem(45);
	color: $color-grey-dark;
	font-weight: 400;
	margin: 0;
	span{
		font-family: "Raleway";
		font-size: rem(20);
		font-weight: 500;
		text-transform: uppercase;
		letter-spacing: 3px;
		color: #ABAAAA;
	}
}

h2{
	color: $color-grey-dark;
	font-size: rem(30);
	font-family: 'Baskerville', serif;
	font-weight: 400;
	font-style: italic;
	line-height: 150%;
	margin: 0;
	margin-bottom: 30px;

	&.is-red{
		color: $color-red;
		font-style: normal;
	}
}

h3{
	font-family: 'Baskerville', serif;
	font-size: rem(30);
	color: $color-grey-dark;
	font-weight: 400;
	margin: 0;
}

p{
	color: $color-grey-dark;
	font-size: rem(16);
	font-family: 'Raleway', sans-serif;
	line-height: 150%;
	margin: 0;
	margin-bottom: 30px;
}

.is-centered{
	text-align: center;
}