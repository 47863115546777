.Slider{
	@include size(100% 590px);
	background-color: rgba(0,0,0,0.05);
	position: relative;
	
	&-content{
		position: relative;
		z-index: 10;
		// .container{
			// position: relative
		// }
	}

	&-arrows{
		@include size(100% 40px);
		@include position(absolute, 0 0 0 0);
		margin: auto;
		z-index: 20;
	}

	&-arrow{
		z-index: 0;
		position: relative;
		// position: absolute;
		&--left{
			float: left;
			// left: 0;
			// right: auto;
		}
		&--right{
			float: right;
			// right: 0;
			// left: auto;
		}
	}
}