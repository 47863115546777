.Form{
	input,textarea{
		display: block;
		width: 100%;
		height: 50px;
		border: none;
		border-bottom: 2px solid #D8D8D8;
		outline: none;
		transition: all 0.3s;

		@include placeholder {
			color: $color-grey-light;
			transition: all 0.3s;
		}

		&:focus{
			color: $color-red;
			border-color: $color-red;
			@include placeholder {
				color: $color-red;
			}
		}

	}

	textarea{
		margin-top: 20px;
		height: 120px;
	}

	&-footer{
		margin-top: 40px;
		p{
			line-height: 54px;
			color: $color-grey-light;
		}
		.Button{
			float: right;
			background-color: #FFF;
			&:hover{
				background-color: $color-grey-btn;
			}
			@media screen and (max-width: 480px){
				width: 100%;
				box-sizing: border-box;
				margin-bottom: 60px;
			}
		}
	}
	
}
.wpcf7-response-output{
    position: relative;
    height: auto;
    clear: both;
    background-color: #303030;
    color: #FFF;
    border: none !important;
    text-align: center;
    padding: 30px 0 !important;
    border-radius: 4px;
    margin: 0 10px 20px 10px !important;
}