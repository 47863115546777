.Header{
	@include size(100% auto); 
	padding: 20px 0 0 0;
	
	&-logo{
		margin-bottom: 25px;
		text-align: center;
		display: block;
		text-align: center;
		img{
			height: 80px;
		}
	}
	&-socials{
		display: inline-block;
		@media screen and (max-width: 650px){
			display: block;
		}
	}
	&-menu{
		height: auto;
		line-height: 25px;
		text-align: center;
		padding: 0;
		
		@media screen and (max-width: 700px){
			line-height: 30px !important;
		}

		&-social{
			margin: 0 10px !important;
			a{
				font-size: rem(18) !important;
			}
			a:after{
				display: none !important;
			}
		}

		&-lang{
			color: $color-grey-light;
		}

		li{
			display: inline-block;
			list-style-type: none;
			position: relative;
			margin: 0 20px;

			a{
				color: $color-grey-light;
				font-size: rem(14);
				text-transform: uppercase;
				text-decoration: none;
				transition: all 0.3s;
				position: relative;

				&:after{
					content: " ";
					@include position(absolute, auto auto -6px 0);
					@include size(100% 2px);
					transform: scale(0);
					transition: all 0.3s;
					transition-delay: 0.1s;
					background-color: $color-red;
				}

			}

			a:hover, &.is-active a{
				color: $color-red;
				&:after{
					transform: scale(1);
				}
			}
			
			&.is-active a{
				font-weight: bold;
			}
		}
	}
}