.Showcase{
	margin-bottom: 60px;
	@media screen and (max-width: 750px){
		margin-bottom: 15px !important;
		position: relative;
		padding-bottom: 300px;
	}

	&-left{
		padding-right: 20px;	
		@media screen and (max-width: 750px){
			padding: 0 !important;
			@include gridle_grid( 12 );
			position: initial !important;
		}
	}
	&-right{
		padding-left: 20px;	
		@media screen and (max-width: 750px){
			padding: 0 !important;
			@include gridle_grid( 12 );
			position: initial !important;
		}
	}
	&-image{
		overflow: hidden;
		height: 100%;
		background-size: cover;
		background-position: center center;
		@media screen and (max-width: 750px){
			width: 100% !important;
			height: 300px !important;
			position: absolute;
			bottom: 0;
		}
		// img{
		// 	height: auto;
		// 	// min-height: 100%;
		// 	min-width: 100%;
		// 	@media screen and (max-width: 750px){
		// 		width: 100% !important;
		// 		height: 300px !important;
		// 		position: absolute;
		// 		bottom: 0;
		// 	}
		// }
	}
	&-text{
		min-height: 350px;
		display: table;
		padding: 30px 0;
		margin: 40px 0;
		width: 100%;
		@media screen and (max-width: 750px){
			padding: 0 20px !important;
			@include gridle_grid( 12 );
			min-height: auto !important;
		}
		&-content{
			display: table-cell;
			vertical-align: middle;
		}
		.Button{
			margin-right: 30px;
			margin-bottom: 15px;

			@media screen and (max-width: 100px) {
				width: 100%;
				padding: 0;
			}
		}
	}

	.Button{
		@media screen and (max-width: 700px){
			width: 100%;
			box-sizing: border-box;
			margin-bottom: 15px;
		}
	}
}