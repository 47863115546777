body{
	font-family: "Raleway", sans-serif;
	font-size: 16px;
	overflow-x: hidden;
	width: 100vw;
	position: relative;
}

html{
	width: 100%;
}

html,
body {
    overflow-x: hidden;
    position: relative;
    height: 100%;
}

@include selection {
  background-color: $color-red;
  color: #FFF;
}

// tools

.no-padding-mobile{
	@media screen and (max-width: 480px){
		padding: 0 !important;
	}
}
.no-padding-left{
	padding-left: 0 !important;
}

.no-padding-right{
	padding-right: 0 !important;
}