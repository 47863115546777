// Import grid settings :
@import 'grid-settings';

// @include gridle_init();

// Generate classes :
// @include gridle_generate_classes();
@include gridle_generate_classes();

// you can generate classes separately if you need :
// @include gridle_generate_classes(default);  	// default is the base state always registered
// @include gridle_generate_classes(mobile); 	// only the mobile state
// @include gridle_generate_classes(all, -helpers); // all states, all packages but the helpers

// generate a center custom class for all the states :
// @include gridle_generate_custom_class( ('center','@','%state') ) {
// 	text-align:center;
// }
// this will produces classes : center, center@mobile, center@tablet, center@ipad-landscape
// for separators, you can use -, --, _, __ or @

// Max size :
.container {
	margin:0 auto;
	max-width:1200px;
	direction: ltr !important;
}

[class*="gr-"] {
	@include gridle_set((
		mobile : (
			grid : 12,
			push : 0,
			pull : 0,
			prefix : 0,
			suffix : 0
		)
	));
}