.Events{
	background-color: #F9F9F9;
	padding: 60px 0;
	height: 400px;
	margin-bottom: 160px;

	.container{
		position: relative;
		overflow: hidden;
	}

	&-header{
		position: relative;
		min-height: 40px;
		margin-bottom: 60px;
		padding: 0px 10px;
	}

	&-header-title{
		h1{
			text-align: left;
			@media screen and (max-width: 700px){
				font-size: rem(28);
				line-height: 45px;
			}
			span{
				@media screen and (max-width: 700px){
					display: none;
				}
			}
		}
	}

	&-header-arrows{
		@include position(absolute, 0 10px auto auto);
	}

	&-header-arrow{
		z-index: 0;
		&:nth-child(1){
			margin-right: 20px;
		}
	}

	&-events{
		@include size(100% 340px);
	}

}