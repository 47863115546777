.Gallery{
	&-item{
		font-size: 0;
		padding-bottom: 20px;
		padding-left: 10px;
		padding-right: 10px;
		position: relative;
		opacity: 0;
		transition: all 0.5s;
		width: calc(50% - 20px);
		float: left !important;

		@media screen and (max-width: 700px){
			width: calc(100% - 20px);
		}
		
		&-image{
			background-size: cover;
			background-position: center center;
			width: 100%;
			min-height: 100px;
			border-radius: 4px;
			&:after{
				content: "";
				display: block;
				padding-bottom: 100%;
			}
		}
		&.is-active{
			opacity: 1;
		}
		&-content{
			position: relative;
		}
		img{
			width: 100%;
			position: relative;
			z-index: 10;
		}
		&-overlay{
			@include position(absolute, 0 0 0 0);
			@include size(100%);
			background-color: rgba(0,0,0,0.5);
			z-index: 20;
			&-wrapper{
				@include size(100%);
				display: table;
			}
			&-content{
				display: table-cell;
				vertical-align: middle;
				text-align: center;
				color: #FFF;
				font-family: 'Baskerville', serif;
				font-size: rem(30);
			}
			opacity: 0;
			transition: all 0.5s;
		}
		&:hover &-overlay{
			opacity: 1;
			cursor: pointer;
		}
	}

	.gr-6{
		@media screen and (max-width: 700px){
			@include gridle_grid( 12 );
		}
	}
}