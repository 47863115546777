.Peoples{
	height: 470px;
	margin-top: 100px;

	.container{
		position: relative;
		padding: 0 60px;
		// overflow: hidden;
	}

	&-arrows{
		// @include position(absolute, 0 0 auto auto);
	}

	&-arrow{
		z-index: 0;
		@include position(absolute, 0 auto 0 0);
		margin: auto;
		&:nth-child(2){
			@include position(absolute, 0 0 !important auto 0);
		}
	}

	&-peoples{
		@include size(100% 470px);
		overflow: hidden;
		// padding: 0 60px;
	}

}