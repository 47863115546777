.Event{

	background-color: #FFF;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
	transition: all 0.6s;

	&-image{
		@include size(100% 275px);
		overflow: hidden;
		position: relative;
		&-src{
			@include position(absolute, 0 0 0 0);
			@include size(100%);
			background-size: cover;
			transition: all 0.6s;
		}
	}

	&-content{
		@include size(100% 150px);
		padding-top: 25px;
	}
	
	&--active{
		h3,p,.Event-date{
			color: $color-red !important;
		}
	}

	&-date{
		font-size: rem(11);
		font-family: "Source Sans Pro";
		text-align: center;
		margin-top: 20px;
		text-transform: uppercase;
		font-weight: 700;
		color: #838383;
	}

	h3{
		font-size: rem(25);
		margin-top: 15px;
		text-align: center;
		color: #303030;
	}

	p{
		margin-top: 20px;
		margin-bottom: 0;
		text-align: center;
		font-size: rem(14);
		padding: 0 20px;
		line-height: 170%;
		color: #838383;
	}

	&:hover{
		.Event-image-src{
			transform: scale(1.1);
		}
		box-shadow: 0 12px 30px 0 rgba(0,0,0,0.1);
		transform: translateY(-10px);
		cursor: pointer;
	}

}