.SubHeader{
	&-title{
		@include margin(35px 0 40px 0);
		h1{
			text-align: center;
		}
		&--left h1{
			text-align: left;
			padding: 0 50px;
		}
		p{
			margin: auto;
			margin-top: 30px;
			color: $color-grey-normal;
			font-size: rem(18);
			text-align: center;
			max-width: 80%;
		}
		&--team p{
			color: #303030;
			font-size: rem(16);
		}
	}
	&-content{
		padding: 0 50px;
		a{
			color: $color-grey-normal;
		}
	}
	&-buttons{
		margin-bottom: 60px;
		text-align: center;
		.Button{
			margin: 0 25px;

			@media screen and (max-width: 800px){
				margin: 5px;
			}
			@media screen and (max-width: 480px){
				width: calc(100% - 20px);
				box-sizing: border-box;
				margin: 0;
				margin-bottom: 15px;
				margin-left: 10px;
			}
		}
	}
}