@font-face {
  font-family: "icons";
  src: url('../fonts/icons.eot');
  src: url('../fonts/icons.eot?#iefix') format('eot'),
       url('../fonts/icons.woff') format('woff'),
       url('../fonts/icons.ttf') format('truetype'),
       url('../fonts/icons.svg#icons') format('svg');
}

%icon {
  position: relative;
  display:inline-block;
  vertical-align: middle;
  font-family:"icons";
  font-style:normal;
  font-weight:normal;
  line-height:1;
  speak:none;
  text-decoration:inherit;
  text-transform:none;
}

@function icon-char($filename) {
  $char: "";

  @if $filename == alert {
    $char: "\E001";
  }
  @if $filename == facebook {
    $char: "\E002";
  }
  @if $filename == instagram {
    $char: "\E003";
  }
  @if $filename == parking {
    $char: "\E004";
  }
  @if $filename == train {
    $char: "\E005";
  }
  @if $filename == twitter {
    $char: "\E006";
  }

  @return $char;
}

@mixin icon($filename) {
  content: icon-char($filename);
}

[class*="icon-"] {
  @extend %icon;
}


.icon-alert{
  &::before {
    @include icon(alert);
  }
}
%icon-alert {
  @include icon(alert);
}

.icon-facebook{
  &::before {
    @include icon(facebook);
  }
}
%icon-facebook {
  @include icon(facebook);
}

.icon-instagram{
  &::before {
    @include icon(instagram);
  }
}
%icon-instagram {
  @include icon(instagram);
}

.icon-parking{
  &::before {
    @include icon(parking);
  }
}
%icon-parking {
  @include icon(parking);
}

.icon-train{
  &::before {
    @include icon(train);
  }
}
%icon-train {
  @include icon(train);
}

.icon-twitter{
  &::before {
    @include icon(twitter);
  }
}
%icon-twitter {
  @include icon(twitter);
}
