.Team{
	position: relative;
	&-image{
		
		min-height: 350px;
		background-size: cover;
		background-position: center center;

		@media screen and (max-width: 700px){
			@include gridle_grid( 12 );
		}

		img{
			height: 350px;
			@media screen and (max-width: 1000px){
				height: auto !important;
				width: 100%;
				padding-top: 50px;
			}
			@media screen and (max-width: 700px){
				width: 50%;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				z-index: -1;
				opacity: 0.15;
			}
		}

	}
	&-wrapper{
		display: table;
		min-height: 350px;
		@media screen and (max-width: 700px){
			@include gridle_grid( 12 );
		}
	}
	&-text{
		display: table-cell;
		vertical-align: middle;
		padding-left: 50px;
		@media screen and (max-width: 700px){
			padding-left: 20px;
			padding-right: 20px;
			padding-top: 50px;
		}
		h3{
			// font-style: italic;
			margin-bottom: 0;
		}
		h4{
		    font-size: 0.875rem;
		    line-height: 170%;
		    color: #838383;
		    font-family: "Raleway";
		    margin-bottom: 30px;
		    margin-top: 5px;
		}
		p{
			color: $color-grey-normal;
			margin-bottom: 30px;
			&:last-child(){
				margin-bottom: 0;
			}
		}
	}
}